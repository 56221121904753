import {
  AuthProvider,
  GlobalStateProvider,
  MessageProvider,
  UtilsProvider,
} from '@pepper/ui'

import Routes from './router'

const App = (): JSX.Element => {
  return (
    <GlobalStateProvider>
      <MessageProvider>
        <AuthProvider>
          <UtilsProvider>
            <Routes />
          </UtilsProvider>
        </AuthProvider>
      </MessageProvider>
    </GlobalStateProvider>
  )
}

export default App
