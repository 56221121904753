import { useCallback, useEffect, useState, useMemo } from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useQuery } from 'react-query'
import clsx from 'clsx'
import { format } from 'date-fns'
import { Calendar } from 'react-feather'
import { Loading3QuartersOutlined } from '@ant-design/icons'

import {
  Modal,
  useUserDetails,
  useAssignment,
  useShowMessage,
  Button,
  Divider,
  useGlobalState,
  Skeleton,
  Spin,
} from '@pepper/ui'

import { getDocsContent } from '@pepper/utils/lib/api/editor/editor'
import {
  getContentAuditStatus,
  postContentAuditStatus,
} from '@pepper/utils/lib/api/assignments/assignments'
import { checkAuthorization } from '@pepper/utils/lib/api/functions/authHelpers'
import {
  getWordCountTooltipText,
  getReadabilityScoreTooltipText,
  getContentSuggestionsTooltipText,
  getDeadlineTooltipText,
  getPlagiarismTooltipText,
  getWordCountPillClass,
  getReadabilityScorePillClass,
  getDeadlinePillClass,
  getContentSuggestionsPillClass,
  getPlagiarismPillClass,
  isEligibleForAudit,
  getAuditPropertyKeys,
} from '@pepper/utils/lib/function/audits'

import { ReactComponent as WordCountIcon } from 'assets/images/circled-a.svg'
import { ReactComponent as ContentSuggestionIcon } from 'assets/images/rotate-downward.svg'
import { ReactComponent as BookOpenIcon } from 'assets/images/book-open.svg'
import { ReactComponent as CopyAltIcon } from 'assets/images/copy-alt.svg'

import styles from './_.module.css'

type AuditModalV2Props = {
  assignmentId: string
  handleSubmit: (type: ButtonActionKeys | null) => void
  onCancel: () => void
  docContentUpdatedCount: number
}

const AuditModalV2 = NiceModal.create<AuditModalV2Props>(
  ({ assignmentId, handleSubmit, onCancel, docContentUpdatedCount }) => {
    const { visible: isModalVisible, remove: removeModal } = useModal()
    const match = useRouteMatch('/document/:assignmentId')
    const isEditorPage = match !== null
    const showMessage = useShowMessage()
    const authorization = checkAuthorization()?.data
    const { data: user } = useUserDetails(
      `/users/${authorization?.userId}`,
      authorization?.userId || ''
    )
    const userRole = user?.role

    const { grammarlySuggestionsCount } = useGlobalState()

    const { data: assignmentDetails, isLoading: isAssignmentDetailsLoading } =
      useAssignment({ assignmentId })
    const { assignmentDeadline, requiredWordCount } = useMemo(() => {
      const assignmentDeadline = assignmentDetails?.deadline || null
      const requiredWordCount = assignmentDetails?.word_count || null
      return { assignmentDeadline, requiredWordCount }
    }, [assignmentDetails])
    const formattedDeadline = assignmentDeadline
      ? format(new Date(assignmentDeadline), 'do MMM yyyy, HH:mm a')
      : ''

    const fetchDocsContent = async () => {
      const response = await getDocsContent({
        id: assignmentId,
      })
      if (response.status === 'ok') {
        return response.data?.word_count
      }
      throw new Error(response.error)
    }
    const { data: wordCount, isLoading: isWordCountLoading } = useQuery(
      ['docsContent', assignmentId, docContentUpdatedCount],
      fetchDocsContent
    )

    const fetchReadabilityScore = async () => {
      const response = await postContentAuditStatus(assignmentId, {
        get_readability_score: true,
      })
      if (response.status === 'ok') {
        return response.data?.readability_score
      }
      throw new Error(response.error)
    }
    const { data: readabilityScore, isLoading: isReadabilityScoreLoading } =
      useQuery<number | undefined>(
        ['readabilityScore', assignmentId, docContentUpdatedCount],
        fetchReadabilityScore
      )

    const updateAuditsData = async () => {
      const response = await getContentAuditStatus({
        assignmentId: assignmentId,
      })
      if (response.status === 'ok') {
        return response
      }
      throw new Error(response.error)
    }
    const {
      data: auditsData,
      isLoading: isAuditsDataLoading,
      refetch: refetchAuditsData,
    } = useQuery<AuditData>(['auditData', assignmentId], updateAuditsData)
    const [auditsKey, auditLimitKey] = useMemo(
      () => getAuditPropertyKeys(auditsData, userRole as CreatorRole),
      [auditsData, userRole]
    )
    const { plagiarismPercent } = useMemo(() => {
      const returnValue = {
        plagiarismPercent: null,
      }
      if (!auditsData?.data) return returnValue
      if (auditsData?.data[auditsKey]?.length) {
        const latestAuditIndex = auditsData.data[auditsKey].length - 1
        const latestAuditData = auditsData.data[auditsKey][latestAuditIndex]
        return {
          plagiarismPercent: latestAuditData.plagiarism_percent,
        }
      }
      return {
        plagiarismPercent: null,
      }
    }, [auditsData, auditsKey])

    const [auditRunCount, setAuditRunCount] = useState(0)
    const postAuditData = useCallback(async () => {
      if (!auditsData?.data) return

      const language = assignmentDetails?.language?.name
      const verticalName = assignmentDetails?.vertical?.name
      const shouldRunAudit = isEligibleForAudit(
        auditsData,
        verticalName,
        language,
        userRole as CreatorRole
      )
      if (!shouldRunAudit) return

      try {
        const maxTries = auditsData?.data[auditLimitKey]
        const existingTries = auditsData?.data[auditsKey]?.length
        if (
          auditRunCount < 1 &&
          (userRole === 'editor' || existingTries < maxTries)
        ) {
          await postContentAuditStatus(assignmentId)
          setAuditRunCount(count => count + 1)
          refetchAuditsData()
        }
      } catch (e) {
        showMessage({
          type: 'error',
          message: 'Failed to run aduit for the assignment',
        })
      }
    }, [
      auditsData,
      assignmentDetails?.language?.name,
      assignmentDetails?.vertical?.name,
      userRole,
      auditLimitKey,
      auditsKey,
      assignmentId,
      refetchAuditsData,
      showMessage,
      auditRunCount,
    ])

    useEffect(() => {
      if (!auditsData?.data) {
        return
      }
      postAuditData()
    }, [auditsData, auditsKey, postAuditData])

    const modalForEmptyContent = useMemo(() => {
      return (
        <Modal
          wrapClassName={clsx(styles.auditModal, styles.emptyModal)}
          title={null}
          footer={null}
          visible={isModalVisible}
        >
          <div className={styles.modalTitle}>Empty assignment</div>
          <div className={styles.confirmationText}>
            The assignment you are trying to submit does not have any content.
          </div>
          <div
            className={clsx(styles.footerButtonGroup, styles.footerGroupLeft)}
          >
            <Button
              type="primary"
              onClick={() => {
                onCancel()
                removeModal()
              }}
              className={styles.dismissModalButton}
            >
              {isEditorPage ? (
                'Go back and edit'
              ) : (
                <Link
                  to={`/document/${assignmentId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to editor
                </Link>
              )}
            </Button>
          </div>
        </Modal>
      )
    }, [isModalVisible, isEditorPage, assignmentId, onCancel, removeModal])

    const modalForAuditSuccess = useMemo(() => {
      return (
        <Modal
          wrapClassName={clsx(styles.auditModal, styles.successModal)}
          title={null}
          footer={null}
          visible={isModalVisible}
        >
          <div className={styles.modalTitle}>Content audit report</div>
          <div className={styles.confirmationText}>
            Great job! your assignment content has no issues and is good for
            submission.
          </div>
          <div
            className={clsx(styles.footerButtonGroup, styles.footerGroupLeft)}
          >
            <Button
              type="primary"
              onClick={() => {
                handleSubmit('audit')
                removeModal()
              }}
              className={styles.dismissModalButton}
            >
              Submit assignment
            </Button>
            <Button
              type="link"
              onClick={() => {
                onCancel()
                removeModal()
              }}
              className={clsx(
                styles.continueButton,
                styles.continueButtonRight
              )}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      )
    }, [handleSubmit, isModalVisible, onCancel, removeModal])

    const isWordCountAcceptable =
      getWordCountPillClass(wordCount, requiredWordCount) === 'Gray'
    const isContentSuggestionsAcceptable =
      getContentSuggestionsPillClass(grammarlySuggestionsCount) === 'Gray'
    const isReadabilityScoreAcceptable =
      getReadabilityScorePillClass(readabilityScore) === 'Gray'
    const isPlagiarismPercentAcceptable =
      getPlagiarismPillClass(Number(plagiarismPercent)) === 'Gray'
    const isDeadlineAcceptable =
      getDeadlinePillClass(assignmentDeadline ?? '') === 'Gray'

    const isAnyDataInLoadingState = useMemo(() => {
      return (
        isWordCountLoading ||
        isReadabilityScoreLoading ||
        isAuditsDataLoading ||
        isAssignmentDetailsLoading
      )
    }, [
      isAssignmentDetailsLoading,
      isAuditsDataLoading,
      isReadabilityScoreLoading,
      isWordCountLoading,
    ])

    const isAuditSuccess = useMemo(() => {
      return (
        !isAnyDataInLoadingState &&
        isWordCountAcceptable &&
        isContentSuggestionsAcceptable &&
        isReadabilityScoreAcceptable &&
        isPlagiarismPercentAcceptable &&
        isDeadlineAcceptable
      )
    }, [
      isAnyDataInLoadingState,
      isContentSuggestionsAcceptable,
      isDeadlineAcceptable,
      isPlagiarismPercentAcceptable,
      isReadabilityScoreAcceptable,
      isWordCountAcceptable,
    ])

    if (!isWordCountLoading && wordCount === 0) {
      return modalForEmptyContent
    }

    if (isAuditSuccess) {
      return modalForAuditSuccess
    }

    return (
      <Modal
        wrapClassName={styles.auditModal}
        title={null}
        footer={null}
        visible={isModalVisible}
      >
        <Skeleton active loading={isAnyDataInLoadingState} paragraph={false}>
          <div className={styles.modalTitle}> Content issues</div>
        </Skeleton>
        <div className={styles.confirmationText}>
          <Skeleton
            active
            loading={isAnyDataInLoadingState}
            paragraph={{ rows: 2 }}
            title={false}
          >
            <p>
              Your assignment content has issues. We highly recommend you go
              back and fix them.
            </p>
          </Skeleton>
          <Skeleton
            active
            loading={isAnyDataInLoadingState}
            paragraph={{ rows: 2 }}
            title={false}
          >
            <p>
              If you decide to submit without resolving content issues, there is
              a high probability of the assignment coming for rework.
            </p>
          </Skeleton>
        </div>
        {!isWordCountAcceptable ||
        isWordCountLoading ||
        isAssignmentDetailsLoading ||
        !wordCount ? (
          <div className={styles.infoCard}>
            <div className={styles.infoHeader}>
              <div className={styles.infoIconWrap}>
                <WordCountIcon />
              </div>
              <span>Word Count</span>
              {isWordCountLoading ||
              !wordCount ||
              isAssignmentDetailsLoading ? (
                <Spin
                  indicator={
                    <Loading3QuartersOutlined
                      spin
                      style={{ color: '#EBEBEE' }}
                    />
                  }
                />
              ) : (
                <span
                  className={clsx(
                    styles.pill,
                    styles[
                      `pill${getWordCountPillClass(
                        wordCount,
                        requiredWordCount
                      )}`
                    ],
                    styles.infoValue
                  )}
                >
                  {wordCount}/{requiredWordCount}
                </span>
              )}
            </div>
            <Divider className={styles.divider} style={{ color: '#FFEFEB' }} />
            <div className={styles.infoBody}>
              <Skeleton
                active
                loading={
                  isWordCountLoading || isAssignmentDetailsLoading || !wordCount
                }
                paragraph={{ rows: 2 }}
                title={false}
              >
                {getWordCountTooltipText(wordCount, requiredWordCount)}
              </Skeleton>
            </div>
          </div>
        ) : null}
        {!isContentSuggestionsAcceptable ? (
          <div className={styles.infoCard}>
            <div className={styles.infoHeader}>
              <div className={styles.infoIconWrap}>
                <ContentSuggestionIcon />
              </div>
              <span>Content Suggestions</span>
              <span
                className={clsx(
                  styles.pill,
                  styles[
                    `pill${getContentSuggestionsPillClass(
                      grammarlySuggestionsCount
                    )}`
                  ],
                  styles.infoValue
                )}
              >
                {grammarlySuggestionsCount}
              </span>
            </div>
            <Divider className={styles.divider} style={{ color: '#FFEFEB' }} />
            <div className={styles.infoBody}>
              {getContentSuggestionsTooltipText(grammarlySuggestionsCount)}
            </div>
          </div>
        ) : null}
        {!isReadabilityScoreAcceptable ||
        !readabilityScore ||
        isReadabilityScoreLoading ? (
          <div className={styles.infoCard}>
            <div className={styles.infoHeader}>
              <div className={styles.infoIconWrap}>
                <BookOpenIcon />
              </div>
              <span>Readability Score</span>
              {isReadabilityScoreLoading || !readabilityScore ? (
                <Spin
                  indicator={
                    <Loading3QuartersOutlined
                      spin
                      style={{ color: '#EBEBEE' }}
                    />
                  }
                />
              ) : (
                <span
                  className={clsx(
                    styles.pill,
                    styles[
                      `pill${getReadabilityScorePillClass(readabilityScore)}`
                    ],
                    styles.infoValue
                  )}
                >
                  {readabilityScore}
                </span>
              )}
            </div>
            <Divider className={styles.divider} style={{ color: '#FFEFEB' }} />
            <div className={styles.infoBody}>
              <Skeleton
                active
                loading={isReadabilityScoreLoading || !readabilityScore}
                paragraph={{ rows: 2 }}
                title={false}
              >
                {getReadabilityScoreTooltipText(readabilityScore)}
              </Skeleton>
            </div>
          </div>
        ) : null}
        {!isPlagiarismPercentAcceptable ||
        !plagiarismPercent ||
        isAuditsDataLoading ? (
          <div className={styles.infoCard}>
            <div className={styles.infoHeader}>
              <div className={styles.infoIconWrap}>
                <CopyAltIcon />
              </div>
              <span>Plagiarism Score</span>
              {isAuditsDataLoading || !plagiarismPercent ? (
                <Spin
                  indicator={
                    <Loading3QuartersOutlined
                      spin
                      style={{ color: '#EBEBEE' }}
                    />
                  }
                />
              ) : (
                <span
                  className={clsx(
                    styles.pill,
                    styles[
                      `pill${getPlagiarismPillClass(Number(plagiarismPercent))}`
                    ],
                    styles.infoValue
                  )}
                >
                  {!plagiarismPercent ? null : Number(plagiarismPercent)}%
                </span>
              )}
            </div>
            <Divider className={styles.divider} style={{ color: '#FFEFEB' }} />
            <div className={styles.infoBody}>
              <Skeleton
                active
                loading={isAuditsDataLoading || !plagiarismPercent}
                paragraph={{ rows: 2 }}
                title={false}
              >
                {getPlagiarismTooltipText(Number(plagiarismPercent))}
              </Skeleton>
            </div>
          </div>
        ) : null}
        {!isDeadlineAcceptable ||
        !assignmentDeadline ||
        isAssignmentDetailsLoading ? (
          <div className={styles.infoCard}>
            <div className={styles.infoHeader}>
              <div className={styles.infoIconWrap}>
                <Calendar size={16} />
              </div>
              <span>Deadline</span>
              {isAssignmentDetailsLoading || !assignmentDeadline ? (
                <Spin
                  indicator={
                    <Loading3QuartersOutlined
                      spin
                      style={{ color: '#EBEBEE' }}
                    />
                  }
                />
              ) : (
                <span
                  className={clsx(
                    styles.pill,
                    styles[
                      `pill${getDeadlinePillClass(assignmentDeadline ?? '')}`
                    ],
                    styles.infoValue
                  )}
                >
                  {formattedDeadline}
                </span>
              )}
            </div>
            <Divider className={styles.divider} style={{ color: '#FFEFEB' }} />
            <div className={styles.infoBody}>
              <Skeleton
                active
                loading={isAssignmentDetailsLoading || !assignmentDeadline}
                paragraph={{ rows: 2 }}
                title={false}
              >
                {getDeadlineTooltipText(assignmentDeadline ?? '', true)}
              </Skeleton>
            </div>
          </div>
        ) : null}
        <div className={styles.footerButtonGroup}>
          <Skeleton
            active
            loading={isAnyDataInLoadingState}
            paragraph={false}
            title={{ width: '150px' }}
          >
            <Button
              type="link"
              onClick={() => {
                handleSubmit('audit')
                removeModal()
              }}
              className={styles.continueButton}
            >
              Submit without resolving
            </Button>
          </Skeleton>
          <Skeleton
            active
            loading={isAnyDataInLoadingState}
            paragraph={false}
            title={{ width: '150px' }}
          >
            <Button
              type="primary"
              onClick={() => {
                onCancel()
                removeModal()
              }}
              className={styles.dismissModalButton}
            >
              {isEditorPage ? (
                'Go back and edit'
              ) : (
                <Link
                  to={`/document/${assignmentId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to editor
                </Link>
              )}
            </Button>
          </Skeleton>
        </div>
      </Modal>
    )
  }
)

export default AuditModalV2
