import NiceModal from '@ebay/nice-modal-react'

import AuditModal from 'components/Modal/AuditModalV2/AuditModalV2'
import AuditConfirmationModal from 'components/Modal/AuditModalV2/AuditConfirmationModal'
import PlagiarismConfirmationModal from 'container/EditDocument/PlagiarismPane/PlagiarismConfirmationModal'
import ReferenceCheckModal from 'components/Modal/ReferenceCheckModal/ReferenceCheckModal'

export const registerModals = (): void => {
  NiceModal.register('audit', AuditModal)
  NiceModal.register('audit-confirmation', AuditConfirmationModal)
  NiceModal.register('plagiarism-confirmation', PlagiarismConfirmationModal)
  NiceModal.register('reference-check', ReferenceCheckModal)
}
