import { useMemo, useEffect } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useQuery } from 'react-query'

import { Modal, Button } from '@pepper/ui'

import { getContentAuditStatus } from '@pepper/utils/lib/api/assignments/assignments'

import styles from './_.module.css'

type AuditModalV2Props = {
  assignmentId: string
  onSubmit: () => void
}

const PlagiarismConfirmationModal = NiceModal.create<AuditModalV2Props>(
  ({ assignmentId, onSubmit }) => {
    const { visible: isModalVisible, remove: removeModal } = useModal()
    const updateAuditsData = async () => {
      const response = await getContentAuditStatus({
        assignmentId,
      })
      if (response.status === 'ok') {
        return response
      }
      throw new Error(response.error)
    }
    const { data: auditsData } = useQuery<AuditData>(
      ['auditData', assignmentId],
      updateAuditsData
    )

    const remainingPlagiarismChecksCount = useMemo(() => {
      if (!auditsData?.data) return null
      const maxTries = auditsData.data.writer_audit_limit
      const existingTries = auditsData.data.writer_audits.length
      return maxTries - existingTries
    }, [auditsData])

    const twoPlagiarismChecksLeftJSX = (
      <div className={styles.confirmationText}>
        You are allowed to view plagiarism suggestions only twice. Do you want
        to do it now?
      </div>
    )
    const onePlagiarismCheckLeftJSX = (
      <div className={styles.confirmationText}>
        <p>
          You are allowed to view plagiarism suggestions only once. We suggest
          you to run plagiarism scan once you have finished drafting your
          content.
        </p>
        <p>Do you want to do it now?</p>
      </div>
    )

    const getModalBody = () => {
      if (remainingPlagiarismChecksCount === 1) return onePlagiarismCheckLeftJSX
      if (remainingPlagiarismChecksCount === 2)
        return twoPlagiarismChecksLeftJSX
      return null
    }

    useEffect(() => {
      if (remainingPlagiarismChecksCount !== 2) {
        onSubmit()
        removeModal()
      }
    }, [removeModal, onSubmit, remainingPlagiarismChecksCount])

    return remainingPlagiarismChecksCount === 2 ? (
      <Modal
        wrapClassName={styles.confirmationModal}
        title={null}
        footer={null}
        visible={isModalVisible}
      >
        <div className={styles.confirmationTitle}>Plagiarism Suggestions</div>
        {getModalBody()}
        <div className={styles.confirmationButtonGroup}>
          <Button
            type="primary"
            onClick={() => {
              onSubmit()
              removeModal()
            }}
            className={styles.continueButton}
          >
            Yes, continue
          </Button>
          <Button
            type="link"
            onClick={() => removeModal()}
            className={styles.dismissModalButton}
          >
            Do it later
          </Button>
        </div>
      </Modal>
    ) : null
  }
)

export default PlagiarismConfirmationModal
