/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikHelpers,
  useField,
} from 'formik'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { UploadFile } from 'antd/lib/upload/interface'
import { RangePickerProps } from 'antd/es/date-picker/generatePicker'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import uniqby from 'lodash.uniqby'
import mime from 'mime'
import get from 'lodash.get'

import ClassicEditor from '@peppercontent/ckeditor5-custom-build'
import { getWordCount } from '@pepper/utils/lib/function/misc'
import { BASE_URL } from '@pepper/utils/lib/api/config'
import getFileExtension from '@pepper/utils/lib/function/getFileExtension'
import {
  CheckCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  PlusOutlined,
  FacebookFilled,
  LinkedinFilled,
  TwitterSquareFilled,
  InstagramFilled,
  InfoCircleOutlined,
  SaveOutlined,
  InfoCircleFilled,
} from '@ant-design/icons'
import {
  Button,
  CheckboxGroup,
  CheckboxGroupProps,
  DatePicker,
  DatePickerProps,
  FileUpload,
  FileUploadProps,
  Input,
  InputProps,
  Password,
  RadioGroup,
  RadioGroupProps,
  Select,
  SelectProps,
  TextArea,
  TextAreaProps,
  Rate,
  EditableSelect,
  EditableSelectProps,
  Tag,
  CommentBox,
  DraggerUploader,
  DragFileUploadProps,
  Tooltip,
  useUtils,
  useShowMessage,
  PopOver,
  RichText,
} from '@pepper/ui'
import noop from '@pepper/utils/lib/function/noop'
import type { TooltipProps } from '@pepper/ui'

import styles from './_.module.css'
import clsx from 'clsx'
import { ReactComponent as TooltipInfo } from 'assets/images/mini_tooltip.svg'

const { RangePicker } = DatePicker

interface FieldProps {
  name: string
  error?: boolean
  label?: React.ReactNode | string
  helperText?: React.ReactNode | string
  className?: string
  size?: SizeType
  showError?: boolean
  onBlur?: React.EventHandler<React.FocusEvent>
  helperErrorText?: string
  required?: boolean
  limit?: number | null
  onSave?: (name: string, value: string | Record<string, any>[] | null) => void
}

export function getFieldComponent<FormValues>({
  type,
  name,
  label,
  helperText,
  className = '',
  fieldProps,
  required,
  toolTip,
  onSave,
  actions,
}: {
  type: FieldComponent
  name: string
  label?: string | ReactNode
  helperText?: string | ReactNode
  className?: string
  fieldProps?: any
  toolTip?: ReactNode
  required?: boolean
  onSave?: (_name: string, value: string | Record<string, any>[] | null) => void
  actions: FormikHelpers<FormValues>
}): ReactNode {
  switch (type) {
    case 'text':
      return (
        <TextField
          name={name}
          toolTip={toolTip}
          className={className}
          label={label}
          helperText={helperText}
          setFieldTouched={actions.setFieldTouched}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'textarea':
      return (
        <TextAreaField
          name={name}
          className={className}
          label={label}
          toolTip={toolTip}
          helperText={helperText}
          setFieldTouched={actions.setFieldTouched}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'password':
      return (
        <PasswordField
          name={name}
          className={className}
          label={label}
          helperText={helperText}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'tel':
      return (
        <PhoneField
          name={name}
          className={className}
          label={label}
          helperText={helperText}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'list':
      return (
        <ListField
          name={name}
          className={className}
          label={label}
          helperText={helperText}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'radio':
      return (
        <RadioField
          name={name}
          className={className}
          label={label}
          helperText={helperText}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'checkbox':
      return (
        <CheckboxField
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'select':
      return (
        <SelectField
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'editable-select':
      return (
        <EditableSelectField
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          required={required}
          onSave={onSave}
          fieldProps={fieldProps}
        />
      )
    case 'file':
      return (
        <FileUploadField
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'multi_line':
    case 'html':
      return (
        <RichTextField
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          required={required}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          fieldProps={fieldProps}
        />
      )
    case 'editor':
      return (
        <RichEditor
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          required={required}
          setFieldValue={actions.setFieldValue}
          fieldProps={fieldProps}
        />
      )
    case 'selection':
      return (
        <SelectionField name={name} className={className} {...fieldProps} />
      )
    case 'datepicker':
      return (
        <DatePickerField
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          required={required}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          fieldProps={fieldProps}
        />
      )
    case 'rangepicker':
      return (
        <RangePickerField
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          required={required}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          fieldProps={fieldProps}
        />
      )
    case 'social':
      return (
        <SocialLinksField
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'rate':
      return (
        <RatingField
          name={name}
          label={label}
          className={className}
          setFieldValue={actions.setFieldValue}
          required={required}
        />
      )
    case 'tagSelect':
      return (
        <TagMultiSelect
          label={label}
          required={required}
          name={name}
          {...fieldProps}
        />
      )
    case 'comment-box':
      return (
        <CommentBoxField
          name={name}
          label={label}
          className={className}
          setFieldValue={actions.setFieldValue}
          required={required}
          fieldProps={fieldProps}
        />
      )
    case 'dragDrop':
      return (
        <DragDropFileUploadField
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          required={required}
          setFieldValue={actions.setFieldValue}
          setFieldTouched={actions.setFieldTouched}
          fieldProps={fieldProps}
        />
      )
    default:
      throw Error('component type not available')
  }
}

/* rate */
interface RatingFieldProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  style?: React.CSSProperties
}

export const RatingField: React.FC<RatingFieldProps> = ({
  className = '',
  label,
  name,
  helperText,
  helperErrorText,
  showError = true,
  required,
  setFieldValue,
  style,
}) => {
  const [field, meta] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''
  const getRatingText = (value = 0) => {
    switch (value) {
      case 1:
        return 'Very poor'
      case 2:
        return 'Poor / Highly irrelevant'
      case 3:
        return 'Satisfactory'
      case 4:
        return 'Good'
      case 5:
        return 'Excellent'
      default:
        return ''
    }
  }
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <div className={styles.ratingContainer}>
          <Rate
            style={style}
            value={field.value || 0}
            onChange={e => setFieldValue(name, e)}
            className={`${errorClass}`}
          />

          <span className={styles.ratingText}>
            {getRatingText(field.value || null)}
          </span>
        </div>
      </label>
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {helperErrorText && (
        <div className={styles.helperText}>
          {meta.error || field.value === '' ? (
            <CloseCircleFilled className={styles.red} />
          ) : (
            <CheckCircleFilled className={styles.green} />
          )}
          {helperErrorText}
        </div>
      )}
    </div>
  )
}

/* text */
interface TextFieldProps extends FieldProps {
  setFieldTouched: (n: string, t: boolean) => void
  fieldProps: InputProps & {
    tooltipProps?: TooltipProps
    inlineSave?: boolean
    handleInlineSave?: (name: string, value: number) => void
  }
  toolTip?: ReactNode
}

export const TextField: React.FC<TextFieldProps> = ({
  className = '',
  label,
  name,
  helperText,
  helperErrorText,
  showError = true,
  required,
  toolTip,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          <Tooltip
            title={toolTip}
            placement="left"
            {...(fieldProps?.tooltipProps ? fieldProps.tooltipProps : {})}
          >
            {label} {toolTip && <InfoCircleOutlined />}
          </Tooltip>
        </span>

        <Input
          {...field}
          {...fieldProps}
          className={`${errorClass}`}
          {...(fieldProps?.inlineSave && {
            suffix: (
              <span className={styles.inlineSave}>
                <SaveOutlined
                  color="#5440eb"
                  onClick={() => {
                    if (typeof fieldProps.handleInlineSave !== 'function') {
                      return
                    }

                    fieldProps?.handleInlineSave(name, field.value)
                  }}
                />
              </span>
            ),
          })}
        />
      </label>

      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
      {helperErrorText && (
        <div className={styles.helperText}>
          {meta.error || field.value === '' ? (
            <CloseCircleFilled className={styles.red} />
          ) : (
            <CheckCircleFilled className={styles.green} />
          )}
          {helperErrorText}
        </div>
      )}
    </div>
  )
}

/* password */
interface PasswordFieldProps extends FieldProps {
  fieldProps: InputProps
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <Password {...field} {...fieldProps} />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* tel */
interface PhoneFieldProps extends FieldProps {
  fieldProps: InputProps
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, t: boolean) => void
}

export const PhoneField: React.FC<PhoneFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  required,
  helperText,
  setFieldValue,
  setFieldTouched,
  fieldProps,
}) => {
  const { country_dial_codes } = useUtils()
  const [field, meta] = useField<PhoneNumber | null>(name)
  const extension = field.value?.extension
  const number = field.value?.number
  const options = uniqby(
    [...country_dial_codes].map(({ dial_code }) => ({
      value: dial_code,
      label: `+${dial_code}`,
    })),
    'value'
  ).sort((a, z) => +a.value - +z.value)

  const selectBefore = (
    <Select
      showSearch={true}
      className={styles.phoneExtensionSelect}
      options={options}
      onChange={value =>
        setFieldValue(name, { extension: value, number: number })
      }
      value={extension}
    />
  )

  const error = (meta.error as any)?.number

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <Input
          addonBefore={selectBefore}
          onBlur={(): void => setFieldTouched(name, true)}
          onChange={(e): void =>
            setFieldValue(name, {
              extension: extension,
              number: e.target.value,
            })
          }
          value={number}
          type="tel"
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} />{' '}
          {error ? error : meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* Rich Text */
interface RichTextFieldProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, t: boolean) => void
  fieldProps: any
}

const editorConfiguration = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    '|',
    'link',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo',
    '|',
    'alignment',
  ],
  link: {
    defaultProtocol: 'http://',
    decorators: {
      openInNewTab: {
        mode: 'automatic',
        label: 'Open in a new tab',
        callback: (url: string) => url,
        defaultValue: true, // This option will be selected by default.
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
}

export const RichTextField = ({
  className = '',
  name,
  label,
  showError = true,
  helperText,
  required,
  setFieldValue,
  setFieldTouched,
  fieldProps,
}: RichTextFieldProps): React.ReactElement => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
      </label>
      <CKEditor
        editor={ClassicEditor}
        onChange={(e: any, editor: { getData: () => any }): void => {
          const data = editor.getData()
          setFieldValue(name, data)
        }}
        onBlur={(): void => setFieldTouched(name, true)}
        config={editorConfiguration}
        data={field.value ? field.value : ''}
      />
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      <div className={styles.errorContainer}>
        {showError && meta.touched && meta.error ? (
          <div className={styles.error}>
            <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
          </div>
        ) : (
          <div />
        )}
        {fieldProps?.showWordCount && (
          <span className={styles.textareaWordCount}>
            {getWordCount(field.value)}
          </span>
        )}
      </div>
    </div>
  )
}

interface RichEditorProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  fieldProps: any
  setFieldTouched?: (n: string) => void
}

/* Rich Text editor */
export const RichEditor = ({
  className = '',
  name,
  label,
  helperText,
  required,
  setFieldValue,
  fieldProps,
  setFieldTouched = noop,
}: RichEditorProps): React.ReactElement => {
  const [field, meta] = useField(name)

  const isAttachFile = fieldProps.isAttachFile
  const isVideoRecord = fieldProps.isVideoRecord
  const isLoomField = isAttachFile && isVideoRecord
  const isTextOnly = !isAttachFile && !isLoomField

  const updateFieldValue = (values: RichTextValues | string): void => {
    setFieldTouched(name)
    setFieldValue(name, values)
    if (fieldProps.tagField && typeof values !== 'string') {
      setFieldValue(fieldProps.tagField, values?.richText)
    }
  }

  const fieldValue: RichTextValues = useMemo(() => {
    if (field.value) return field.value
    if (isLoomField) return { richText: '', attachments: [], loomUrls: [] }
    if (isTextOnly) return ''
  }, [field.value, isLoomField, isTextOnly])

  return (
    <div className={clsx(className, styles.editorWrap)}>
      <label>
        <span
          className={clsx(styles.labelText, {
            [styles.inputLabelV2]: fieldProps?.loadV2,
          })}
        >
          {label}
          {required && <span className={styles.requiredText}>*</span>}
          {fieldProps.popoverInfo && (
            <PopOver content={fieldProps.popoverInfo}>
              <InfoCircleFilled className={styles.infoIcon} />
            </PopOver>
          )}
          {fieldProps?.miniTooltip && (
            <Tooltip
              placement="top"
              title={() => {
                return fieldProps.miniTooltip ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: fieldProps.miniTooltip,
                    }}
                  />
                ) : null
              }}
            >
              <TooltipInfo className={styles.tooltipPos} />
            </Tooltip>
          )}
        </span>
      </label>
      <RichText
        {...fieldProps}
        placeholder={fieldProps.placeholder}
        onChange={updateFieldValue}
        fieldData={fieldValue}
        loomButtonId={name}
      />
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* textarea */
interface TextAreaFieldProps extends FieldProps {
  fieldProps: TextAreaProps & { tooltipProps?: TooltipProps }
  setFieldTouched: (n: string, t: boolean) => void
  toolTip?: ReactNode
}

export const TextAreaField: React.FC<TextAreaFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  toolTip,
  required,
  setFieldTouched,
  fieldProps,
}) => {
  const [field, meta] = useField<string>(name)

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          <Tooltip
            title={toolTip}
            placement="left"
            {...(fieldProps?.tooltipProps ? fieldProps.tooltipProps : {})}
          >
            {label} {toolTip && <InfoCircleOutlined />}
          </Tooltip>
        </span>
        <TextArea
          {...field}
          {...fieldProps}
          onBlur={(): void => setFieldTouched(name, true)}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      <div className={styles.errorContainer}>
        {showError && meta.touched && meta.error ? (
          <div className={styles.error}>
            <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
          </div>
        ) : (
          <div />
        )}
        {fieldProps?.showWordCount && (
          <span className={styles.textareaWordCount}>
            {getWordCount(field.value)}
          </span>
        )}
      </div>
    </div>
  )
}

/* comment box */
interface CommentBoxFieldProps extends FieldProps {
  fieldProps: {
    className?: string
    maxLength?: number
    showCount?: boolean
  }
  setFieldValue: (n: string, v: any) => void
  setFieldTouched?: (n: string) => void
}

export const CommentBoxField: React.FC<CommentBoxFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  setFieldValue,
  setFieldTouched = noop,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  const error: any = meta.error

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <CommentBox
          formData={field.value}
          onChange={(values: CommentBox) => {
            setFieldTouched(name)
            setFieldValue(name, values)
          }}
          showSendButton={false}
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && error?.comment ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {error.comment}
        </div>
      ) : null}
    </div>
  )
}

/* text list field  (on enter the text is be added to the list) */
interface ListFieldProps extends FieldProps {
  fieldProps?: InputProps
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, t: boolean) => void
}

export const ListField: React.FC<ListFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  setFieldValue,
  setFieldTouched,
  required,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  const [value, setValue] = useState<string>('')

  const addItem = (event: any): void => {
    event.preventDefault()
    setFieldValue(name, [...field.value, event.target.value])
    setValue('')
  }

  const removeItem = (index: number): void => {
    const result = [
      ...field.value?.slice(0, index),
      ...field.value?.slice(index + 1),
    ]
    setFieldValue(name, result)
  }

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <Input
          onChange={(e): void => setValue(e.target.value)}
          onBlur={(): void => setFieldTouched(name, true)}
          onPressEnter={addItem}
          value={value}
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      <ol className={styles.list}>
        {field.value?.map((item: string, index: number) => (
          <li key={index}>
            <span>
              {index + 1}.&nbsp;&nbsp;
              {item}
            </span>
            <Button size="small" type="text">
              <DeleteOutlined onClick={(): void => removeItem(index)} />
            </Button>
          </li>
        ))}
      </ol>
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* selection field */
interface SelectionFieldProps extends FieldProps {
  options: {
    label: string
    value: string
    suffix?: string | React.ReactNode
    disabled?: boolean
  }[]
}

export const SelectionField: React.FC<SelectionFieldProps> = ({
  className = '',
  name,
  helperText,
  showError = true,
  options,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={`${className} ${styles.selectionField}`}>
      {options.map(option => (
        <label
          key={option.value}
          className={field.value === option.value ? styles.selected : ''}
        >
          {!option.disabled && (
            <>
              {field.value === option.value ? (
                <CheckCircleFilled />
              ) : (
                <CheckCircleOutlined />
              )}
            </>
          )}
          <input
            className={styles.visiblityHidden}
            {...field}
            type="radio"
            value={option.value}
            disabled={option.disabled}
          />
          <div className={styles.labelValue}>
            <span>{option.label}</span> <span>{option.suffix}</span>
          </div>
        </label>
      ))}
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* select field */
interface SelectFieldProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, t: boolean) => void
  fieldProps: SelectProps
}

export const SelectField: React.FC<SelectFieldProps> = ({
  className = '',
  label,
  name,
  required,
  showError = true,
  helperText,
  setFieldValue,
  setFieldTouched,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''

  return (
    <div className={`${className} ${styles.selectField}`}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>} {label}
        </span>
        <Select
          className={`w-100 ${errorClass}`}
          optionFilterProp="label"
          onChange={(value): void => setFieldValue(name, value)}
          onBlur={(): void => setFieldTouched(name, true)}
          value={field.value}
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* Editable Select Field */
interface EditableSelectFieldProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, t: boolean) => void
  fieldProps: EditableSelectProps
}

export const EditableSelectField: React.FC<EditableSelectFieldProps> = ({
  className = '',
  label,
  name,
  required,
  showError = true,
  helperText,
  setFieldValue,
  setFieldTouched,
  fieldProps,
  onSave,
}) => {
  const [field, meta] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''

  const handleSave = () => {
    if (onSave) onSave(field.name, field.value)
  }

  return (
    <div className={`${className} ${styles.selectField}`}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <EditableSelect
          className={`w-100 ${errorClass}`}
          optionFilterProp="label"
          onChange={value => {
            if (typeof fieldProps?.maxTagAllowed !== 'number') {
              setFieldValue(name, value)
              return
            }

            if (!Array.isArray(value)) {
              setFieldValue(name, value)
              return
            }

            if (value.length <= fieldProps.maxTagAllowed) {
              setFieldValue(name, value)
              return
            }

            if (value.length < get(meta, 'value.length', [])) {
              setFieldValue(name, value)
            }
          }}
          onBlur={(): void => setFieldTouched(name, true)}
          value={field.value}
          onSave={handleSave}
          preventChange={(value): void => setFieldValue(name, value)}
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* radio field */
interface RadioFieldProps extends FieldProps {
  fieldProps: RadioGroupProps
}

export const RadioField: React.FC<RadioFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <RadioGroup {...field} {...fieldProps} />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* radio field */
interface CheckboxFieldProps extends FieldProps {
  fieldProps: CheckboxGroupProps
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, t: boolean) => void
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  setFieldValue,
  // setFieldTouched,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <span>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <CheckboxGroup
          onChange={(values): void => setFieldValue(name, values)}
          value={field.value}
          {...fieldProps}
        />
      </span>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* datepicker */
interface DatePickerFieldProps extends FieldProps {
  fieldProps: DatePickerProps
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, t: boolean) => void
}

export const DatePickerField: React.FC<DatePickerFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  setFieldValue,
  setFieldTouched,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <DatePicker
          onChange={(value): void => setFieldValue(name, value)}
          value={field.value ? new Date(field.value) : undefined}
          onBlur={(): void => setFieldTouched(name, true)}
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* rangepicker */
interface RangePickerFieldProps extends FieldProps {
  fieldProps: RangePickerProps<Date>
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, t: boolean) => void
}

export const RangePickerField: React.FC<RangePickerFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  setFieldValue,
  setFieldTouched,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
        <RangePicker
          onChange={(value: any): void => setFieldValue(name, value)}
          value={field.value}
          onBlur={(): void => setFieldTouched(name, true)}
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* file */
interface FileUploadFieldProps extends FieldProps {
  fieldProps: Omit<FileUploadProps, 'handleFiles'>
  setFieldValue: (n: string, v: any) => void
  setFieldTouched?: (n: string, t: boolean) => void
}

export const FileUploadField = ({
  className = '',
  name,
  label,
  helperText,
  required,
  setFieldValue,
  fieldProps,
}: FileUploadFieldProps): React.ReactElement => {
  const [field, meta] = useField(name)
  const showMessage = useShowMessage()

  const transformFile = (file: UploadFile) => {
    const fileExtension = getFileExtension(file.name)

    let fileType = file.type || mime.getType(file.name)

    if (!fileType && fileExtension === 'docx') {
      fileType = `application/vnd.openxmlformats-officedocument.wordprocessingml.document`
    }

    if (!fileType && fileExtension === 'pdf') {
      fileType = `application/pdf`
    }

    if (!fileType) {
      showMessage({
        type: 'error',
        message:
          'File type is missing. Please upload a file with a valid type.',
      })

      return file
    }

    return { ...file, type: fileType }
  }

  const handleFiles = (files: UploadFile | UploadFile[]) => {
    if (!files) return

    if (Array.isArray(files)) {
      const nextFiles = files
        .map(file => transformFile(file))
        .filter(file => file.type)

      setFieldValue(name, nextFiles)
      return
    }

    const nextFile = transformFile(files)

    if (nextFile.type) {
      setFieldValue(name, nextFile)
    }
  }

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
      </label>
      <FileUpload
        {...fieldProps}
        handleFiles={handleFiles}
        defaultFileList={field.value}
      />
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {typeof meta.error === 'string' ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
      {get(meta, 'error.type', '') ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} />{' '}
          {get(meta, 'error.type')}
        </div>
      ) : null}
      {Array.isArray(meta.error) &&
        meta.error.filter(err => err?.type).length > 0 && (
          <div className={styles.error}>
            <CloseCircleFilled className={styles.errorIcon} /> File type is
            missing. Please upload a file with a valid type.
          </div>
        )}
    </div>
  )
}

/* file */
interface FileUploaderForEditorProps {
  fieldProps: Omit<FileUploadProps, 'handleFiles'>
  loadEditorView?: boolean
  assignmentId?: string
  isAuthorized?: boolean
  uploadImage?: (
    files: {
      url: string
      fileItem: any
    }[]
  ) => void
  setFileLoaderLoading?: (val: boolean) => void
}

export const FileUploaderForEditor = ({
  fieldProps,
  loadEditorView,
  assignmentId,
  isAuthorized,
  uploadImage,
  setFileLoaderLoading,
}: FileUploaderForEditorProps): React.ReactElement => {
  return (
    <>
      <FileUpload
        loadEditorView={loadEditorView}
        setFileLoaderLoading={setFileLoaderLoading}
        isAuthorized={isAuthorized}
        assignmentId={assignmentId}
        {...fieldProps}
        allowedFiles={['.jpg', '.png', '.jpeg']}
        handleFiles={(files: any): void => {
          if (files?.length > 0) {
            uploadImage?.(
              files?.map((fileItem: { id: string }) => {
                return {
                  url: `${BASE_URL}/files/${fileItem.id}`,
                  fileItem,
                }
              })
            )
          }
        }}
      />
    </>
  )
}

/* social */
interface SocialLinksProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, t: boolean) => void
  fieldProps?: InputProps
}

export const SocialLinksField: React.FC<SocialLinksProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  setFieldValue,
  setFieldTouched,
  fieldProps,
}) => {
  const [field, meta] = useField<string[]>(name)
  const [linkCount, setLinkCount] = useState(0)

  useEffect(() => {
    setLinkCount(field.value?.length ?? 0)
  }, [field.value])

  const removeField = (
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ): void => {
    setLinkCount(linkCount - 1)
    arrayHelpers.remove(index)
  }

  const getSocialIcon = (link: string): React.ReactNode => {
    const props = { className: styles.socialPrefixIcon }
    if (link.includes('facebook.com')) return <FacebookFilled {...props} />
    if (link.includes('linkedin.com')) return <LinkedinFilled {...props} />
    if (link.includes('twitter.com')) return <TwitterSquareFilled {...props} />
    if (link.includes('instagram.com')) return <InstagramFilled {...props} />
    else return <span style={{ width: 16 }}></span>
  }

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
      </label>

      <div className={styles.addLinkContainer}>
        <FieldArray
          name={name}
          render={(arrayHelpers): React.ReactNode => (
            <>
              {[...Array(linkCount)].map((_, index) => (
                <Input
                  className={styles.socialField}
                  prefix={getSocialIcon(field.value[index] ?? '')}
                  onBlur={(): void => setFieldTouched(name, true)}
                  suffix={
                    <DeleteOutlined
                      className={styles.socialSuffixIcon}
                      onClick={(): void => removeField(index, arrayHelpers)}
                    />
                  }
                  value={field.value[index]}
                  onChange={(e): void =>
                    setFieldValue(`${name}.${index}`, e.target.value)
                  }
                  key={index}
                  {...fieldProps}
                />
              ))}
            </>
          )}
        />
      </div>
      <Button
        icon={<PlusOutlined />}
        onClick={() => setLinkCount(linkCount + 1)}
        type="link"
        size="small"
        disabled={linkCount >= 5}
      >
        Add New Link
      </Button>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} />
          Please Enter Valid Url
        </div>
      ) : null}
    </div>
  )
}

/** Tag Select */
interface TagMultiSelectProps extends FieldProps {
  options: {
    id: number | string
    title: string
    color: string
  }[]
}

export const TagMultiSelect: React.FC<TagMultiSelectProps> = ({
  showError = true,
  label,
  required,
  options,
  name,
}) => {
  const [field, meta, helper] = useField(name)
  return (
    <div>
      <div>
        {label ? (
          <label>
            <span className={styles.labelText}>
              {required && <span className={styles.requiredText}>*</span>}
              {label}
            </span>
          </label>
        ) : (
          ''
        )}
        <div className={styles.blocksSelection}>
          {options.map(option => (
            <label key={option.id} className={styles.tagBlock}>
              <Tag
                color={
                  field?.value?.includes(option.id.toString())
                    ? option.color
                    : 'default'
                }
              >
                {option.title}
              </Tag>
              <input
                className={styles.visibilityHidden}
                {...field}
                onChange={(e): void => {
                  const i = field?.value?.findIndex(
                    (val: string) => val === e.target.value
                  )
                  if (i === -1) {
                    helper.setValue([...field.value, e.target.value])
                  } else {
                    helper.setValue(
                      field.value.filter(
                        (val: string) => val !== e.target.value
                      )
                    )
                  }
                }}
                type="checkbox"
                value={option.id.toString()}
              />
            </label>
          ))}
        </div>
      </div>
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* drag and drop uploader */
interface DragDropFileUploadFieldProps extends FieldProps {
  fieldProps: Omit<DragFileUploadProps, 'handleFiles'>
  setFieldValue: (n: string, v: any) => void
  setFieldTouched?: (n: string, t: boolean) => void
  onChange?: (files: Attachment[]) => void
  minimal?: boolean
}

export const DragDropFileUploadField = ({
  className = '',
  name,
  label,
  helperText,
  required,
  setFieldValue,
  fieldProps,
  onChange,
  minimal,
}: DragDropFileUploadFieldProps): React.ReactElement => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {required && <span className={styles.requiredText}>*</span>}
          {label}
        </span>
      </label>
      <DraggerUploader
        {...fieldProps}
        minimal={minimal}
        handleFiles={(files): void => {
          setFieldValue(name, files)
          if (onChange) onChange(files)
        }}
        defaultFileList={field.value}
      />
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}
