import React from 'react'
import ReactDOM from 'react-dom'

import { datadogRum } from '@datadog/browser-rum'

// * * antd.less is imported above App component to avoid antd overriding styles from css modules
import '@pepper/ui/src/styles/index.css'
import '@pepper/ui/src/styles/grid.css'
import './App.less'
// import './styles/index.css'

import App from './App'
import { registerModals } from './modals'

if (process.env.REACT_APP_DD_ENABLE === 'TRUE') {
  datadogRum.init({
    applicationId: 'b2b783bf-5e36-4cb6-a292-4d2bb18a84b3',
    clientToken: 'pub225cc58b63c26e2629f722b02387c763',
    site: 'datadoghq.com',
    service: 'creator-portal',
    env: 'prod',
    sampleRate: 100,
    trackInteractions: true,
    version: process.env.REACT_APP_PACKAGE_VERSION || '0.0.0',
  })
}

// Register the modals
registerModals()

ReactDOM.render(<App />, document.getElementById('root'))
