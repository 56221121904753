import NiceModal, { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import { useRouteMatch, Link } from 'react-router-dom'

import { Modal, Button } from '@pepper/ui'

import styles from './_.module.css'

type AuditConfirmationModalProps = {
  assignmentId: string
  handleSubmit: (type: ButtonActionKeys | null) => void
  onCancel: () => void
}

const AuditConfirmationModal = NiceModal.create<AuditConfirmationModalProps>(
  ({ assignmentId, handleSubmit, onCancel }) => {
    const { visible: isModalVisible, remove: removeModal } = useModal()
    const { show: showAuditModal } = useModal('audit')
    const match = useRouteMatch('/document/:assignmentId')

    const handleContinueClick = () => {
      removeModal()
      onCancel()
      showAuditModal({
        assignmentId: assignmentId,
        handleSubmit: (type: ButtonActionKeys) => {
          handleSubmit(type)
        },
        onCancel: () => {
          onCancel()
        },
      })
    }

    const isEditorPage = match !== null

    return (
      <Modal
        wrapClassName={clsx(styles.auditModal, styles.successModal)}
        title={null}
        footer={null}
        visible={isModalVisible}
      >
        <div className={styles.modalTitle}>Audit and submit?</div>
        <div className={styles.confirmationText}>
          You only get to audit the assignment twice at most; please ensure that
          your submission scores well in grammar, readability, required word
          count and plagiarism.
        </div>
        <div className={clsx(styles.footerButtonGroup, styles.footerGroupLeft)}>
          <Button
            type="primary"
            onClick={() => {
              removeModal()
              onCancel()
            }}
            className={styles.dismissModalButton}
          >
            {isEditorPage ? (
              'Go back and edit'
            ) : (
              <Link
                to={`/document/${assignmentId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to editor
              </Link>
            )}
          </Button>
          <Button
            type="link"
            onClick={handleContinueClick}
            className={clsx(styles.continueButton, styles.continueButtonRight)}
          >
            Yes, continue
          </Button>
        </div>
      </Modal>
    )
  }
)

export default AuditConfirmationModal
