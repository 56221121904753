import React, { lazy, Suspense, useState, useEffect } from 'react'
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import NiceModal from '@ebay/nice-modal-react'

import { Spin, GTMPageView, useShowMessage } from '@pepper/ui'

import { checkAuthorization } from '@pepper/utils/lib/api/functions/authHelpers'
import { checkTokenStatus } from '@pepper/utils/lib/api/functions/refreshToken'

import ErrorBoundary from './ErrorBoundary'

const Dashboard = lazy(() => import('container/Dashboard/Dashboard'))
const ContentAudit = lazy(
  () => import('container/AssignmentDetails/ContentAudit/ContentAudit')
)
const Document = lazy(() => import('container/Document/Document'))

const publicRoutes = [
  {
    path: '/404',
    exact: true,
    component: lazy(() => import('components/Placeholder')),
  },
  {
    path: '/500',
    exact: true,
    component: lazy(() => import('components/Placeholder')),
  },
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('container/Login/Login')),
  },
  {
    path: '/signup',
    exact: true,
    component: lazy(() => import('container/Signup/Signup')),
  },
  {
    path: '/forgot-password',
    exact: true,
    component: lazy(() => import('container/ForgotPassword/ForgotPassword')),
  },
  {
    path: '/setup/complete',
    exact: true,
    component: lazy(
      () => import('container/AccountSetup/SplashScreen/OnboardingComplete')
    ),
  },
  {
    path: '/setup',
    exact: false,
    component: lazy(() => import('container/AccountSetup/AccountSetup')),
  },
  {
    path: '/forgot-password',
    exact: true,
    component: lazy(() => import('container/AccountSetup/AccountSetup')),
  },
  {
    path: '/unsubscribe-email',
    exact: false,
    component: lazy(
      () => import('container/UnsubscribeEmail/UnsubscribeEmail')
    ),
  },
  {
    path: '/assignment/action/:type',
    exact: false,
    component: lazy(
      () => import('container/WhatsAppAssignment/WhatsAppAssignment')
    ),
  },
  {
    path: '/editor',
    exact: false,
    component: lazy(() => import('container/EditDocument/EditorRoot')),
  },
  {
    path: '/d/:assignmentId',
    exact: false,
    component: lazy(() => import('container/PublicDocument/PublicDocument')),
  },
]

const PrivateRoute: React.FC<{
  path: string
}> = ({ children, path }) => {
  const location = useLocation()
  const history = useHistory()
  const [isAuthorised, setIsAuthorised] = useState<'ok' | 'error'>('ok')
  const showMessage = useShowMessage()

  useEffect(() => {
    checkTokenStatus()
  }, [])

  useEffect(() => {
    const { status, error } = checkAuthorization()
    if (status === 'error') {
      showMessage({ type: 'error', message: error })
    }
    setIsAuthorised(status)
  }, [history, showMessage])

  return (
    <>
      {isAuthorised === 'ok' ? (
        <Route path={path}>
          <NiceModal.Provider>{children}</NiceModal.Provider>
        </Route>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    </>
  )
}

const Routes: React.FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Spin className="spinner-full" />}>
        <Router>
          <GTMPageView />
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              <PrivateRoute path="/content-audit">
                <ContentAudit />
              </PrivateRoute>
              <PrivateRoute path="/document/:assignmentId">
                <Document />
              </PrivateRoute>
              <PrivateRoute path="/">
                <Dashboard />
              </PrivateRoute>
            </Switch>
          </QueryParamProvider>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}

export default Routes
